import { useEffect, useState, ChangeEvent } from "react";
import axios from "axios";
import styled, { css, useTheme } from "styled-components";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import {
  Box,
  Button,
  Header,
  InputImage,
  Modal,
  ModalContent,
  ModalFooter,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Input,
} from "common/components";
import { IBanner } from "types";
import useImage from "hooks/imageHook";

const Img = styled.div<{ src?: string }>`
  ${(props) => css`
    width: 100px;
    height: 70px;
    background-image: url(${props.src
      ? `${process.env.REACT_APP_IMG_URL}${props.src}`
      : "/images/no-image.png"});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  `}
`;

const Banner = () => {
  const theme = useTheme();
  const [addAndEditModal, setAddAndEditModal] = useState<"edit" | "add" | null>(
    null
  );
  const [actionLoading, setActionLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedBanner, setSelectedBanner] = useState<IBanner | null>(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [banners, setBanners] = useState<IBanner[]>([]);
  const [link, setLink] = useState<string | undefined>();
  const image = useImage();

  const changeLink = (e: ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  const hideModal = () => {
    setAddAndEditModal(null);
    setDeleteModal(false);
    setErrorMsg("");
    image.reset();
    setSelectedBanner(null);
    setLink(undefined);
  };

  useEffect(() => {
    axios.get("/banner").then(({ data }) => {
      setBanners(data);
    });
  }, []);

  const addBanner = () => {
    setActionLoading(true);
    if (addAndEditModal === "add") {
      axios
        .post("/banner/create", {
          imgId: image.image?.id,
          link,
        })
        .then(({ data }) => {
          setBanners([data, ...banners]);
          setActionLoading(false);
          hideModal();
        })
        .catch((e) => {
          setActionLoading(false);
          setErrorMsg(e.message);
        });
    } else {
      axios
        .post(`/banner/${selectedBanner?.id}/update`, {
          imgId: image.image?.id,
          link,
        })
        .then(({ data }) => {
          const clone = [...banners];
          const index = clone.findIndex((i) => i.id === data.id);
          clone[index].image = data.image;
          clone[index].link = data.link;

          setBanners(clone);
          setActionLoading(false);
          hideModal();
        })
        .catch((e) => {
          setActionLoading(false);
          setErrorMsg(e.message);
        });
    }
  };

  const showAddModal = () => {
    setAddAndEditModal("add");
  };

  const onDelete = () => {
    setActionLoading(true);
    axios
      .post(`/banner/${selectedBanner?.id}/delete`)
      .then(() => {
        const clone = [...banners];
        const newBanners = clone.filter((i) => i.id !== selectedBanner?.id);
        setBanners(newBanners);
        setActionLoading(false);
        hideModal();
      })
      .catch((e) => {
        setActionLoading(false);
        hideModal();
      });
  };

  return (
    <Box>
      <Header
        title={`Сурталчилгаа (${banners.length})`}
        rightBtn={{ title: "Сурталчилгаа нэмэх", onClick: showAddModal }}
      />
      <Table>
        <Thead>
          <Tr>
            <Th minWidth="50px">
              <Box textAlign="right">#</Box>
            </Th>
            <Th>
              <Box textAlign="left">Зураг</Box>
            </Th>
            <Th width="100%">
              <Box textAlign="left">Холбоос</Box>
            </Th>
            <Th>
              <Box textAlign="left">Огноо</Box>
            </Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {banners.map((item, index) => {
            const onClickEdit = () => {
              setAddAndEditModal("edit");
              setSelectedBanner(item);
              setLink(item?.link);
            };

            console.log(item);

            const onClickDelete = () => {
              setDeleteModal(true);
              setSelectedBanner(item);
            };

            return (
              <Tr key={item.id}>
                <Td>
                  <Box fontFamily="Roboto" textAlign="right">
                    {index + 1}
                  </Box>
                </Td>
                <Td>
                  <Box fontFamily="Roboto" display="flex" alignItems="center">
                    <Box marginRight={`${theme.spacing.xs}em`}>
                      <Img src={item.image?.path} />
                    </Box>
                  </Box>
                </Td>
                <Td>
                  <Box fontFamily="Roboto">
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {item.link}
                    </a>
                  </Box>
                </Td>
                <Td>
                  <Box fontFamily="Roboto">{item.createdAt}</Box>
                </Td>
                <Td>
                  <Box display="flex">
                    <IconButton
                      icon={faEdit}
                      color={theme.palettes.orange}
                      onClick={onClickEdit}
                    />
                    <Box ml={`${theme.spacing.xs}em`}>
                      <IconButton
                        icon={faTrash}
                        color={theme.palettes.red}
                        onClick={onClickDelete}
                      />
                    </Box>
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Modal
        isVisible={addAndEditModal !== null}
        hide={hideModal}
        title={addAndEditModal === "add" ? "НЭМЭХ" : `ЗАСАХ`}
      >
        <ModalContent>
          <Box flex={1}>
            <InputImage
              upload={image.upload}
              isLoading={image.isLoading}
              img={image.image?.path || selectedBanner?.image?.path}
              errorMsg={image.errorMsg}
            />
          </Box>
          <Box flex={1}>
            <Input
              placeholder="https://mastermind.mn/"
              label="Холбоос"
              defaultValue={selectedBanner?.link}
              value={link}
              onChange={changeLink}
            />
          </Box>
          {errorMsg && (
            <Box
              fontFamily="Roboto"
              color="error"
              mt={`${theme.spacing.s}em`}
              fontSize={`${theme.text.s}em`}
            >
              {errorMsg}
            </Box>
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            title={addAndEditModal === "add" ? "Нэмэх" : "Засах"}
            onClick={addBanner}
            isLoading={actionLoading}
          />
        </ModalFooter>
      </Modal>
      <Modal isVisible={deleteModal} hide={hideModal} title="Устгах">
        <ModalContent>
          <Box display="flex" fontFamily="Roboto">
            Та{" "}
            <Box color="error" px={`${theme.spacing.xs}em`}>
              {selectedBanner?.id}
            </Box>{" "}
            дугаартай сурталчилгааг устгахдаа итгэлтэй байна уу?
          </Box>
        </ModalContent>
        <ModalFooter>
          <Button title="Буцах" onClick={hideModal} />
          <Box ml={`${theme.spacing.m}em`}>
            <Button
              title="Устгах"
              onClick={onDelete}
              bgColor={theme.palettes.red}
              textColor={theme.palettes.white}
              isLoading={actionLoading}
            />
          </Box>
        </ModalFooter>
      </Modal>
    </Box>
  );
};

export default Banner;
