import styled, { css, useTheme } from "styled-components";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { IconButton } from "common/components";
import { IImage } from "types";

interface Props {
  img: IImage;
  remove: (id: number) => void;
}

const Container = styled.label`
  ${(props) => css`
    position: relative;
    display: inline-block;
    height: 100px;
    width: 100px;
    border: 1px solid ${props.theme.colors.border};
    border-radius: ${props.theme.borderRadii.m}em;
    align-items: center;
    background: ${props.theme.palettes.white};
    justify-content: center;
    margin-top: ${props.theme.spacing.m}em;
    margin-left: ${props.theme.spacing.m}em;
    margin-right: ${props.theme.spacing.m}em;
    &:hover > ${ButtonContainer} {
      opacity: 1;
    }
  `}
`;

const Image = styled.div<{ src: string }>`
  ${(props) => css`
    width: 100%;
    height: 100%;
    background-image: url(${props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  `}
`;

const ButtonContainer = styled.div`
  ${(props) => css`
    position: absolute;
    top: ${props.theme.spacing.xs}em;
    right: ${props.theme.spacing.xs}em;
    opacity: 0;
  `}
`;

const Gallery = (props: Props) => {
  const theme = useTheme();

  const remove = async () => {
    props.remove(props.img.id);
  };

  return (
    <Container>
      <Image src={props.img.path!} />
      <ButtonContainer>
        <IconButton
          icon={faTrash}
          color={theme.colors.error}
          onClick={remove}
        />
      </ButtonContainer>
    </Container>
  );
};

export default Gallery;
